@media screen and (max-width: 400px) {
    .list_Patients {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_patients {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_patients {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_patients_overview {
        
        content: none;
    }
    .box_layout_patients_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    .list_Patients {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_patients {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_patients {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_patients_overview {
        
        content: none;
    }
    .box_layout_patients_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    .list_Patients {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1010px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_patients {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        width: inherit;
    }
    .box_layout_overview_patients {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_patients_overview {
        
        content: none;
    }
    .box_layout_patients_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        width: inherit;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
    .searchBoxShown {
        position: absolute;
        background-color: #fff;
        border: 2px solid #112694;
        border-radius: 6px;
        width: 296px;
        max-width: 300px;
        top: 88px;
        right: 212px;
        z-index: 1;
    }
    .searchBoxHidden {
        display: none; 
    }
    .searchDropdown{
        display: block;
    }
    .searchResult {
        list-style: none;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .list_Patients {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1165px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_patients {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        width: inherit;
    }
    .box_layout_overview_patients {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
        width: inherit;
    }
    .box_patients_overview {
        
        content: none;
    }
    .box_layout_patients_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        width: inherit;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
    .searchBoxShown {
        position: absolute;
        background-color: #fff;
        border: 2px solid #112694;
        border-radius: 6px;
        width: 296px;
        max-width: 300px;
        top: 88px;
        left: 366px;
        z-index: 1;
    }
    .searchBoxHidden {
        display: none; 
    }
    .searchDropdown{
        display: block;
    }
    .searchResult {
        list-style: none;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .list_Patients {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1685px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_patients {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_patients {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_patients_overview {
        
        content: none;
    }
    .box_layout_patients_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display: flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
    @supports (-moz-appearance:none) {
        .searchBoxShown {
            right: 580px !important;
        }
    }
    .searchBoxHidden {
        display: none; 
    }
    .searchDropdown{
        display: block;
        padding: 20px;
        margin: 0;
    }
    .searchResult {
        list-style: none;
    }

    /* Chrome 29+ */
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
        .searchBoxShown { 
            position: absolute;
            background-color: #fff;
            border: 2px solid #112694;
            border-radius: 6px;
            width: 296px;
            max-width: 300px;
            top: 88px;
            right: 590px;
            z-index: 1;
        }
    }

}

@media screen and (min-width: 1921px) {
    .list_Patients {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_patients {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_patients {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_patients_overview {
        
        content: none;
    }
    .box_layout_patients_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
}