.block {
    display: flex;
    flex-direction: column;
    background-color: rgba(196, 196, 196, 0.803);
    border: 1px solid rgba(174, 174, 174, 0.803);
}
.block_layout {
    position: relative;
    min-height: 180px;
    flex-shrink: 0;
}
.flex {
    display: flex;
}
.flex_layout {
    position: relative;
    height: min-content;
    margin: 20px 32px;
}
.flex_item {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 139px;
}
.image {
    background: var(--src) center center / contain no-repeat;
}
.image_layout {
    position: relative;
    height: 139px;
    margin: 1px 0px 0px;
}
.flex_spacer {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 44px;
}
.flex_item1 {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 260px;
}
.medium_title {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    font: 20px "Inter", Helvetica, Arial, serif;
    color: #000;
    letter-spacing: 0px;
    white-space: pre-wrap;
}
.medium_title_box_layout {
    position: relative;
    height: min-content;
    margin: 18px 0px 6px;
}
.flex_spacer1 {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 71px;
}
.flex_item2 {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 260px;
}
.medium_title1_box_layout {
    position: relative;
    height: min-content;
    margin: 18px 0px 35px;
}
.flex_spacer2 {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 74px;
}
.medium_title1 {
    font: 20px "Inter", Helvetica, Arial, serif;
    color: #000;
    letter-spacing: 0px;
}
.medium_title1_layout {
    position: relative;
    flex: 0 0 auto;
    height: min-content;
    margin: 18px 130px 93px 0px;
}

@media screen and (max-width: 400px) {
    .block {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .block_layout {
        position: relative;
        min-height: 180px;
        flex-shrink: 0;
    }
    .flex {
        display: flex;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 20px 32px;
    }
    .flex_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 139px;
    }
    .image {
        background: var(--src) center center / contain no-repeat;
    }
    .image_layout {
        position: relative;
        height: 139px;
        margin: 1px 0px 0px;
    }
    .flex_spacer {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 44px;
    }
    .flex_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .medium_title_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 6px;
    }
    .flex_spacer1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 71px;
    }
    .flex_item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 35px;
    }
    .flex_spacer2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 74px;
    }
    .medium_title1 {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .medium_title1_layout {
        position: relative;
        flex: 0 0 auto;
        height: min-content;
        margin: 18px 130px 93px 0px;
    }
    
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    .block {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .block_layout {
        position: relative;
        min-height: 180px;
        flex-shrink: 0;
    }
    .flex {
        display: flex;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 20px 32px;
    }
    .flex_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 139px;
    }
    .image {
        background: var(--src) center center / contain no-repeat;
    }
    .image_layout {
        position: relative;
        height: 139px;
        margin: 1px 0px 0px;
    }
    .flex_spacer {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 44px;
    }
    .flex_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .medium_title_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 6px;
    }
    .flex_spacer1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 71px;
    }
    .flex_item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 35px;
    }
    .flex_spacer2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 74px;
    }
    .medium_title1 {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .medium_title1_layout {
        position: relative;
        flex: 0 0 auto;
        height: min-content;
        margin: 18px 130px 93px 0px;
    }
    
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    .block {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .block_layout {
        position: relative;
        min-height: 180px;
        flex-shrink: 0;
    }
    .flex {
        display: flex;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 20px 32px;
    }
    .flex_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 139px;
    }
    .image {
        background: var(--src) center center / contain no-repeat;
    }
    .image_layout {
        position: relative;
        height: 139px;
        margin: 1px 0px 0px;
    }
    .flex_spacer {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 44px;
    }
    .flex_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .medium_title_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 6px;
    }
    .flex_spacer1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 71px;
    }
    .flex_item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 35px;
    }
    .flex_spacer2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 74px;
    }
    .medium_title1 {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .medium_title1_layout {
        position: relative;
        flex: 0 0 auto;
        height: min-content;
        margin: 18px 130px 93px 0px;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .block {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .block_layout {
        position: relative;
        min-height: 180px;
        flex-shrink: 0;
    }
    .flex {
        display: flex;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 20px 32px;
    }
    .flex_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 139px;
    }
    .image {
        background: var(--src) center center / contain no-repeat;
    }
    .image_layout {
        position: relative;
        height: 139px;
        margin: 1px 0px 0px;
    }
    .flex_spacer {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 44px;
    }
    .flex_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .medium_title_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 6px;
    }
    .flex_spacer1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 71px;
    }
    .flex_item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 35px;
    }
    .flex_spacer2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 74px;
    }
    .medium_title1 {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .medium_title1_layout {
        position: relative;
        flex: 0 0 auto;
        height: min-content;
        margin: 18px 130px 93px 0px;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .block {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .block_layout {
        position: relative;
        min-height: 180px;
        flex-shrink: 0;
    }
    .flex {
        display: flex;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 20px 32px;
    }
    .flex_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 139px;
    }
    .image {
        background: var(--src) center center / contain no-repeat;
    }
    .image_layout {
        position: relative;
        height: 139px;
        margin: 1px 0px 0px;
    }
    .flex_spacer {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 44px;
    }
    .flex_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .medium_title_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 6px;
    }
    .flex_spacer1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 71px;
    }
    .flex_item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 35px;
    }
    .flex_spacer2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 74px;
    }
    .medium_title1 {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .medium_title1_layout {
        position: relative;
        flex: 0 0 auto;
        height: min-content;
        margin: 18px 130px 93px 0px;
    }
    
}

@media screen and (min-width: 1921px) {
    .block {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .block_layout {
        position: relative;
        min-height: 180px;
        flex-shrink: 0;
    }
    .flex {
        display: flex;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 20px 32px;
    }
    .flex_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 139px;
    }
    .image {
        background: var(--src) center center / contain no-repeat;
    }
    .image_layout {
        position: relative;
        height: 139px;
        margin: 1px 0px 0px;
    }
    .flex_spacer {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 44px;
    }
    .flex_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .medium_title_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 6px;
    }
    .flex_spacer1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 71px;
    }
    .flex_item2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 1 260px;
    }
    .medium_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 18px 0px 35px;
    }
    .flex_spacer2 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 74px;
    }
    .medium_title1 {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .medium_title1_layout {
        position: relative;
        flex: 0 0 auto;
        height: min-content;
        margin: 18px 130px 93px 0px;
    }
    
}
