/* main {
    width: 100% !important;
    grid-area: 1/ 1 / 3 / 3 !important;
    margin-top: 0px !important;
} */

.signInForm {
    width: 100% !important;
    grid-area: 1/ 1 / 3 / 3 !important;
    margin-top: 0px !important;
}

@media screen and (max-width: 400px) {
    .leftGrid {

    }
}
@media screen and (min-width: 401px) and (max-width: 720px) {
    .leftGrid {

    }
}
@media screen and (min-width: 721px) and (max-width: 1280px) {
    .signInForm {
        overflow: hidden;
      }
    .leftGrid {
        max-height: 800px;
    }
    .loginBgVideo {
        max-height: 800px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .signInForm {
        overflow: hidden;
      }
    .leftGrid {
        max-height: 900px;
    }
    .loginBgVideo {
        max-height: 900px;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .signInForm {
        overflow: hidden;
      }
    .leftGrid {
        max-height: 927px;
    }
    .loginBgVideo {
        max-height: 927px;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1536px) {
    body {
        overflow: hidden;
      }
    .leftGrid {
        max-height: 711px;
    }
    .loginBgVideo {
        max-height: 711px;
    }
}

@media screen and (min-width: 1921px) {
    .leftGrid {

    }
}
