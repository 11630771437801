.patient_info {
    margin-left: -8px;
}
.block {
    display: flex;
    background-color: #fff;
}
.block_layout {
    position: relative;
    min-height: 1024px;
    flex-shrink: 0;
}
.block_item {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 210px;
}
.content_box {
    display: flex;
    flex-direction: column;
    background-color: #122795;
}
.content_box_layout {
    position: relative;
    height: min-content;
}
.cover_block {
    display: flex;
    flex-direction: column;
}
.cover_block_layout {
    position: relative;
    height: min-content;
    width: 59.52%;
    margin: 35px 18.1% 0px 22.38%;
}
.box4 {
    border: 5px solid rgba(255, 255, 255, 0.498);
    border-radius: 30px 30px 30px 30px;
}
.box4_layout {
    position: absolute;
    top: 0px;
    height: 125px;
    left: 0px;
    right: 0px;
}
.hero_title1 {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    font: 38.789px "Inter", Helvetica, Arial, serif;
    color: #fff;
    text-align: center;
    letter-spacing: 0px;
    white-space: pre-wrap;
}
.hero_title1_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero_title1_box_layout {
    position: relative;
    height: min-content;
    margin: 24.5px 0px;
}
.cover_block1 {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 30px 0px 0px 30px;
}
.cover_block1_layout {
    position: relative;
    height: min-content;
    margin: 42px 0px 0px 30px;
}
.big_title2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 25px "Inter", Helvetica, Arial, serif;
    color: #112694;
    text-align: center;
    letter-spacing: 0px;
}
.big_title2_layout {
    position: relative;
    height: min-content;
    width: 51.11%;
    margin: 22px 29.17% 22px 19.72%;
}
.big_title1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 25px "Inter", Helvetica, Arial, serif;
    color: #fff;
    text-align: center;
    letter-spacing: 0px;
}
.big_title1_layout {
    position: relative;
    height: min-content;
    width: 64.76%;
    margin: 42px 14.52% 0px 20.71%;
}
.big_title1_layout1 {
    position: relative;
    height: min-content;
    width: 51.43%;
    margin: 64px 21.19% 0px 27.38%;
}
.big_title1_layout2 {
    position: relative;
    height: min-content;
    width: 43.81%;
    margin: 42px 25% 0px 31.19%;
}
.big_title1_layout3 {
    position: relative;
    height: min-content;
    width: 59.52%;
    margin: 42px 17.14% 294px 23.33%;
}
.block_item1 {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 1230px;
}
.flex {
    display: flex;
    flex-direction: column;
}
.flex_layout {
    position: relative;
    height: min-content;
    margin: 0px 0px 26px;
    min-width: 1693px;
}
.content_box1 {
    display: flex;
    flex-direction: column;
    background-color: rgba(196, 196, 196, 0.803);
    border: 1px solid rgba(174, 174, 174, 0.803);
}
.content_box1_layout {
    position: relative;
    height: min-content;
}
.hero_title {
    display: flex;
    align-items: center;
    justify-content: left;
    font: 500 50px "Inter", Helvetica, Arial, serif;
    color: #000;
    text-align: center;
    letter-spacing: 0px;
}
.hero_title_layout {
    position: relative;
    height: min-content;
    width: 45.77%;
    margin: 22.5px 52.76% 0px 1.46%;
}
.big_title {
    display: flex;
    align-items: center;
    font: 500 25px "Inter", Helvetica, Arial, serif;
    color: #000;
    letter-spacing: 0px;
}
.big_title_layout {
    position: relative;
    height: min-content;
    width: 90.57%;
    margin: 9.5px 25px 40px;
}
.box_add_consult {
    background-color: #ebebeb;
    border-radius: 30px 30px 30px 30px;
}
.box_layout_add_consult {
    position: relative;
    height: max-content;
    margin: 39px 37px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    align-content: center;
}

.box_patient {
    background-color: #ebebeb;
    padding-top: 20px;
    padding-left: 250px;
    border-radius: 30px 30px 30px 30px;
}
.box_layout_patient {
    position: relative;
    height: 779px;
    margin: 39px 37px 0px;
}

.box_patient_grid {
    position: relative;
    height: min-content;
    margin: 0px 0px 26px;
    max-width: 1075px;
}
.box_layout_patient_grid {
    display: grid;
    grid-auto-flow: row dense;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 39px 39px; 
    grid-template-areas:
    "one two"
    "three four"; 
    width: 100%; 
}

.box_complaints {
    grid-area: one;
    background-color: #fff;
    border-radius: 30px 30px 30px 30px;
    height: 325px;
}
.box_visits {
    grid-area: two;
    background-color: #fff;
    border-radius: 30px 30px 30px 30px;
    height: 325px;
}
.box_history {
    grid-area: three;
    background-color: #fff;
    border-radius: 30px 30px 30px 30px;
    height: 325px;
}
.box_records {
    grid-area: four;
    background-color: #122795;
    border-radius: 30px 30px 30px 30px;
    height: 325px;
}
.actions_box {
    border-radius: 30px 30px 30px 30px;
    background-color: #ebebeb;
    padding: 10px;
}

.actions_box_layout {
    display:flex;
    justify-content: flex-end;
    margin-top: 39px;
    margin-left: 39px;
    margin-right: 39px;
}

@media screen and (max-width: 400px) {
    .patient_info {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_add_patient {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_add_patient {
        position: relative;
        height: max-content;
        margin: 39px 37px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        align-content: center;
    }
    
    .box_patient {
        background-color: #ebebeb;
        padding-top: 20px;
        padding-left: 250px;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_patient {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
    }
    
    .box_patient_grid {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        max-width: 1075px;
    }
    .box_layout_patient_grid {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 39px 39px; 
        grid-template-areas:
        "one two"
        "three four"; 
        width: 100%; 
    }
    
    .box_complaints {
        grid-area: one;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_visits {
        grid-area: two;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_history {
        grid-area: three;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_records {
        grid-area: four;
        background-color: #122795;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    .patient_info {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_add_patient {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_add_patient {
        position: relative;
        height: max-content;
        margin: 39px 37px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        align-content: center;
    }
    
    .box_patient {
        background-color: #ebebeb;
        padding-top: 20px;
        padding-left: 250px;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_patient {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
    }
    
    .box_patient_grid {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        max-width: 1075px;
    }
    .box_layout_patient_grid {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 39px 39px; 
        grid-template-areas:
        "one two"
        "three four"; 
        width: 100%; 
    }
    
    .box_complaints {
        grid-area: one;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_visits {
        grid-area: two;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_history {
        grid-area: three;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_records {
        grid-area: four;
        background-color: #122795;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    .patient_info {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1010px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1060px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_add_patient {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_add_patient {
        position: relative;
        height: max-content;
        margin: 39px 37px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        align-content: center;
        width: inherit;
    }
    
    .box_patient {
        background-color: #ebebeb;
        padding-top: 20px;
        padding-left: 250px;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_patient {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
    }
    
    .box_patient_grid {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        max-width: 1075px;
    }
    .box_layout_patient_grid {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 39px 39px; 
        grid-template-areas:
        "one two"
        "three four"; 
        width: 100%; 
    }
    
    .box_complaints {
        grid-area: one;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_visits {
        grid-area: two;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_history {
        grid-area: three;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_records {
        grid-area: four;
        background-color: #122795;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        width: inherit;
    }
    .complaintDate {
        position: relative;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .patient_info {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1165px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1220px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_add_consult {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_add_consult {
        position: relative;
        height: max-content;
        margin: 39px 37px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        align-content: center;
        width: inherit;
    }
    .box_patient {
        background-color: #ebebeb;
        padding-top: 20px;
        padding-left: 250px;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_patient {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
    }
    
    .box_patient_grid {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        max-width: 1075px;
    }
    .box_layout_patient_grid {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 39px 39px; 
        grid-template-areas:
        "one two"
        "three four"; 
        width: 100%; 
    }
    .box_complaints {
        grid-area: one;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_visits {
        grid-area: two;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_history {
        grid-area: three;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_records {
        grid-area: four;
        background-color: #122795;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        width: inherit;
    }
    .complaintDate {
        position: relative;
    }

    .complaintInput {
        width: inherit;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .patient_info {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_add_consult {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        padding: 30px;
    }
    .box_layout_add_consult {
        position: relative;
        height: max-content;
        margin: 39px 37px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        align-content: center;
    }
    .box_patient {
        background-color: #ebebeb;
        padding-top: 20px;
        padding-left: 250px;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_patient {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
    }
    .box_consult_grid {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        max-width: 900px;
    }
    .box_layout_consult_grid {
        display: grid; 
        grid-auto-flow: column dense; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 39px; 
        grid-template-areas: 
        "one two three";
        width: 100%; 
    }
    .box_dates {
        grid-area: one;
        /* background-color: #fff; */
        /* border-radius: 30px 30px 30px 30px; */
        height: inherit;
        width: 20%;
        float: left;
        align-self: center;
    }
    .box_stepper {
        grid-area: two;
        /* background-color: #fff;
        border-radius: 30px 30px 30px 30px; */
        width: 80%;
        height: inherit;
        float: left;
    }
    /* .box_history {
        grid-area: three;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_records {
        grid-area: four;
        background-color: #122795;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    } */
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .complaintDate {
        position: relative;
    }
    .notBrowserSupported {
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }
    .notBrowserSupported:hover {
        background-color: #555 !important;
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }
    .notBrowserSupported:hover > svg {
        color: #fff !important;
    }
    .labsModal {
        display: grid; 
        grid-auto-flow: column dense; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 39px; 
        grid-template-areas: 
          "one two"; 
        justify-content: stretch; 
        align-items: center; 
        width: 100%; 
    }
    .labsModalStyle {
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%);
        width: 900px;
        background-color: #fff;
        border: 2px solid #112694;
        border-radius: 25px;
        box-shadow: 24px;
        padding: 30px
    }
    .labsModalAreaOne {
        grid-area: one;
        width: 160px;
        height: 750px;
        border-right: 2px solid #112694;
        padding-right: 10px;
    }
    .labsModalAreaTwo {
        grid-area: two;
        width: calc(900px - 170px);
        height: 750px;
        margin-left: -25px;
    }
    .labsVerticalTabsLeft {
        max-width: 150px;
    }
    .panelGeneral {
        width: 100%;
    }
    .panelGynae {
        width: 100%;
    }
    .panelCovid {
        width: 100%;
    }
    .stepsConnector {
        grid-area: two;
        width: 25px;
    }
    .stepsConnectorStep1 {
        margin-top: -6px;
        margin-left: 7.5px;
        width: 3px;
        border-right: 2px solid #555;
    }
    .stepsConnectorStep2 {
        margin-top: -6px;
        margin-left: 7.5px;
        margin-bottom: -2px;
        width: 3px;
        border-right: 2px solid #555;
    }
    .stepsConnectorStep3 {
        margin-top: -6px;
        margin-left: 7.5px;
        margin-bottom: -2px;
        width: 3px;
        border-right: 2px solid #555;
    }
    .stepsConnectorStep4 {
        margin-top: -6px;
        margin-left: 7.5px;
        margin-bottom: -2px;
        width: 3px;
        border-right: 2px solid #555;
    }
    .stepsConnectorStep5 {
        margin-top: -6px;
        margin-left: 7.5px;
        margin-bottom: -2px;
        width: 3px;
        border-right: 2px solid #555;
    }
    .ClinicalNotes {
        margin-top: 75px;
    }
    .Investigations {
        margin-top: 55px;
    }
    .DiagnosisManagement {
        margin-top: 75px;
    }
    .Script {
        margin-top: 75px;
    }
    .modalCloseBtn {
        height: 32px;
        width: 32px;
        background-color: grey;
        color: #fefefe;
        border-radius: 50%;
        top: -382px;
        left: -65px;
    }
    .modalCloseBtn:hover {
        height: 32px;
        width: 32px;
        background-color: #112694;
        color: #fff;
        border-radius: 50%;
        top: -382px;
        left: -65px;
    }
    
}

@media screen and (min-width: 1921px) {
    .patient_info {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_add_patient {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_add_patient {
        position: relative;
        height: max-content;
        margin: 39px 37px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        align-content: center;
    }
    
    .box_patient {
        background-color: #ebebeb;
        padding-top: 20px;
        padding-left: 250px;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_patient {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
    }
    
    .box_patient_grid {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        max-width: 1075px;
    }
    .box_layout_patient_grid {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 39px 39px; 
        grid-template-areas:
        "one two"
        "three four"; 
        width: 100%; 
    }
    
    .box_complaints {
        grid-area: one;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_visits {
        grid-area: two;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_history {
        grid-area: three;
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_records {
        grid-area: four;
        background-color: #122795;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    
}