.overview {
    margin-left: -8px;
}
.block {
    display: flex;
    background-color: #fff;
}
.block_layout {
    position: relative;
    min-height: 1024px;
    flex-shrink: 0;
}
.block_item {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 210px;
}
.content_box {
    display: flex;
    flex-direction: column;
    background-color: #122795;
}
.content_box_layout {
    position: relative;
    height: min-content;
}
.cover_block {
    display: flex;
    flex-direction: column;
}
.cover_block_layout {
    position: relative;
    height: min-content;
    width: 59.52%;
    margin: 35px 18.1% 0px 22.38%;
}
.box4 {
    border: 5px solid rgba(255, 255, 255, 0.498);
    border-radius: 30px 30px 30px 30px;
}
.box4_layout {
    position: absolute;
    top: 0px;
    height: 125px;
    left: 0px;
    right: 0px;
}
.hero_title1 {
    overflow: visible;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    font: 38.789px "Inter", Helvetica, Arial, serif;
    color: #fff;
    text-align: center;
    letter-spacing: 0px;
    white-space: pre-wrap;
}
.hero_title1_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hero_title1_box_layout {
    position: relative;
    height: min-content;
    margin: 24.5px 0px;
}
.cover_block1 {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 30px 0px 0px 30px;
}
.cover_block1_layout {
    position: relative;
    height: min-content;
    margin: 42px 0px 0px 30px;
}
.big_title2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 25px "Inter", Helvetica, Arial, serif;
    color: #112694;
    text-align: center;
    letter-spacing: 0px;
}
.big_title2_layout {
    position: relative;
    height: min-content;
    width: 51.11%;
    margin: 22px 29.17% 22px 19.72%;
}
.big_title1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font: 25px "Inter", Helvetica, Arial, serif;
    color: #fff;
    text-align: center;
    letter-spacing: 0px;
}
.big_title1_layout {
    position: relative;
    height: min-content;
    width: 64.76%;
    margin: 42px 14.52% 0px 20.71%;
}
.big_title1_layout1 {
    position: relative;
    height: min-content;
    width: 51.43%;
    margin: 64px 21.19% 0px 27.38%;
}
.big_title1_layout2 {
    position: relative;
    height: min-content;
    width: 43.81%;
    margin: 42px 25% 0px 31.19%;
}
.big_title1_layout3 {
    position: relative;
    height: min-content;
    width: 59.52%;
    margin: 42px 17.14% 294px 23.33%;
}
.block_item1 {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 1 1230px;
}
.flex {
    display: flex;
    flex-direction: column;
}
.flex_layout {
    position: relative;
    height: min-content;
    margin: 0px 0px 26px;
    width: 1630px;
}
.flex_overview {
    display: grid;
    grid-auto-flow: row dense;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 5px 19px; 
    grid-template-areas:
    "one two three"; 
    width: 100%; 
}
.flex_layout_overview {
    position: relative;
    height: min-content;
    margin: 39px 0px 0px 35px;
    min-width: auto;
    max-width: inherit;
}
.content_box1 {
    display: flex;
    flex-direction: column;
    background-color: rgba(196, 196, 196, 0.803);
    border: 1px solid rgba(174, 174, 174, 0.803);
}
.content_box1_layout {
    position: relative;
    height: min-content;
    width: 1687px;
    z-index: 999;
    margin-left: 210px;
    margin-top: -8px;
}
.hero_title {
    display: flex;
    align-items: center;
    justify-content: left;
    font: 500 50px "Inter", Helvetica, Arial, serif;
    color: #000;
    text-align: center;
    letter-spacing: 0px;
}
.hero_title_layout {
    position: relative;
    height: min-content;
    width: 45.77%;
    margin: 22.5px 52.76% 0px 1.46%;
}
.big_title {
    display: flex;
    align-items: center;
    font: 500 25px "Inter", Helvetica, Arial, serif;
    color: #000;
    letter-spacing: 0px;
}
.big_title_layout {
    position: relative;
    height: min-content;
    width: 90.57%;
    margin: 9.5px 25px 40px;
}
.box_overview_1 {
    grid-area: one;
    background-color: #ebebeb;
    border-radius: 30px 30px 30px 30px;
    height: 325px;
}
.box_overview_2 {
    grid-area: two;
    background-color: #ebebeb;
    border-radius: 30px 30px 30px 30px;
    height: 685px;
}
.box_overview_3 {
    grid-area: three;
    background-color: #ebebeb;
    border-radius: 30px 30px 30px 30px;
    height: 685px;
}
.box_overview_4 {
    grid-area: one;
    background-color: #ebebeb;
    border-radius: 30px 30px 30px 30px;
    height: 325px;
    margin-top: 360px;
}
.box_layout_overview {
    position: relative;
}
.noteButton {
    position: absolute !important;
    bottom: 10px !important;
    left: 200px;
    border: 1px solid #000 !important;
    border-radius: 25px !important;
    background: #fff !important;
    padding: 7.5px 25px !important;
}
.notes_title {
    align-items: center;
    font: 500 25px "Inter", Helvetica, Arial, serif;
    color: #000;
    letter-spacing: 0px;
}
.notes_title_layout {
    position: absolute;
    left: 180px;
}
.box_list_note_layout_overview {
    position: absolute;
    top: 45px;
    padding-left: 20px;
    padding-right: 20px;
    max-height: 604px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.box_list_note_layout_overview::-webkit-scrollbar {
    display: none;
}
.noteStyle {
    margin: 20px auto;
    padding: 20px;
    border: 1px Solid #333;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    width: 450px;
}

@media screen and (max-width: 400px) {
    .overview {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: row;
        position: relative;
        flex: 0 1 210px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1630px;
    }
    .flex_overview {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 5px 19px; 
        grid-template-areas:
        "one two three"; 
        width: 100%; 
    }
    .flex_layout_overview {
        position: relative;
        height: min-content;
        margin: 39px 0px 0px 35px;
        min-width: auto;
        max-width: inherit;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 238px;
        z-index: 999;
        margin-left: 112px;
        margin-top: -8px;
        height: 159px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 34px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        /* width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%; */
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_overview_1 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_overview_2 {
        grid-area: two;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_3 {
        grid-area: three;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_4 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
        margin-top: 360px;
    }
    .box_layout_overview {
        position: relative;
    }
    .noteButton {
        position: absolute !important;
        bottom: 10px !important;
        left: 200px;
        border: 1px solid #000 !important;
        border-radius: 25px !important;
        background: #fff !important;
        padding: 7.5px 25px !important;
    }
    .notes_title {
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .notes_title_layout {
        position: absolute;
        left: 180px;
    }
    .box_list_note_layout_overview {
        position: absolute;
        top: 45px;
        padding-left: 20px;
        padding-right: 20px;
        max-height: 604px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .box_list_note_layout_overview::-webkit-scrollbar {
        display: none;
    }
    
    
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    .overview {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1630px;
    }
    .flex_overview {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 5px 19px; 
        grid-template-areas:
        "one two three"; 
        width: 100%; 
    }
    .flex_layout_overview {
        position: relative;
        height: min-content;
        margin: 39px 0px 0px 35px;
        min-width: auto;
        max-width: inherit;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_overview_1 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_overview_2 {
        grid-area: two;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_3 {
        grid-area: three;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_4 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
        margin-top: 360px;
    }
    .box_layout_overview {
        position: relative;
    }
    .noteButton {
        position: absolute !important;
        bottom: 10px !important;
        left: 200px;
        border: 1px solid #000 !important;
        border-radius: 25px !important;
        background: #fff !important;
        padding: 7.5px 25px !important;
    }
    .notes_title {
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .notes_title_layout {
        position: absolute;
        left: 180px;
    }
    .box_list_note_layout_overview {
        position: absolute;
        top: 45px;
        padding-left: 20px;
        padding-right: 20px;
        max-height: 604px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .box_list_note_layout_overview::-webkit-scrollbar {
        display: none;
    }
    
    
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    .overview {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1010px;
    }
    .flex_overview {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 5px 19px; 
        grid-template-areas:
        "one two three"; 
        width: 100%; 
    }
    .flex_layout_overview {
        position: relative;
        height: min-content;
        margin: 39px 0px 0px 35px;
        min-width: auto;
        max-width: inherit;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1060px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_overview_1 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_overview_2 {
        grid-area: two;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_3 {
        grid-area: three;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_4 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
        margin-top: 360px;
    }
    .box_layout_overview {
        position: relative;
    }
    .noteButton {
        position: absolute !important;
        bottom: 10px !important;
        left: 200px;
        border: 1px solid #000 !important;
        border-radius: 25px !important;
        background: #fff !important;
        padding: 7.5px 25px !important;
    }
    .notes_title {
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .notes_title_layout {
        position: absolute;
        left: 85px;
    }
    .box_list_note_layout_overview {
        position: absolute;
        top: 45px;
        padding-left: 20px;
        padding-right: 20px;
        max-height: 604px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .box_list_note_layout_overview::-webkit-scrollbar {
        display: none;
    }
    
    .noteStyle {
        margin: 20px auto;
        padding: 20px;
        border: 1px Solid #333;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        width: 245px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .overview {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex_overview {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 5px 19px; 
        grid-template-areas:
        "one two three"; 
        width: 100%; 
    }
    .flex_layout_overview {
        position: relative;
        height: min-content;
        margin: 39px 0px 0px 35px;
        min-width: auto;
        max-width: inherit;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1220px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_overview_1 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_overview_2 {
        grid-area: two;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_3 {
        grid-area: three;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_4 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
        margin-top: 360px;
    }
    .box_layout_overview {
        position: relative;
    }
    .noteButton {
        position: absolute !important;
        bottom: 10px !important;
        left: 200px;
        border: 1px solid #000 !important;
        border-radius: 25px !important;
        background: #fff !important;
        padding: 7.5px 25px !important;
    }
    .notes_title {
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .notes_title_layout {
        position: absolute;
        left: 110px;
    }
    .box_list_note_layout_overview {
        position: absolute;
        top: 45px;
        padding-left: 20px;
        padding-right: 20px;
        max-height: 604px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .box_list_note_layout_overview::-webkit-scrollbar {
        display: none;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1165px;
    }
    .noteStyle {
        margin: 20px auto;
        padding: 20px;
        border: 1px Solid #333;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        width: 295px;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .overview {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1630px;
    }
    .flex_overview {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 5px 19px; 
        grid-template-areas:
        "one two three"; 
        width: 100%; 
    }
    .flex_layout_overview {
        position: relative;
        height: min-content;
        margin: 39px 0px 0px 35px;
        min-width: auto;
        max-width: inherit;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_overview_1 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_overview_2 {
        grid-area: two;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_3 {
        grid-area: three;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_4 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
        margin-top: 360px;
    }
    .box_layout_overview {
        position: relative;
    }
    .noteButton {
        position: absolute !important;
        bottom: 10px !important;
        left: 200px;
        border: 1px solid #000 !important;
        border-radius: 25px !important;
        background: #fff !important;
        padding: 7.5px 25px !important;
    }
    .notes_title {
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .notes_title_layout {
        position: absolute;
        left: 180px;
    }
    .box_list_note_layout_overview {
        position: absolute;
        top: 45px;
        padding-left: 20px;
        padding-right: 20px;
        max-height: 604px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .box_list_note_layout_overview::-webkit-scrollbar {
        display: none;
    }
    
    
}

@media screen and (min-width: 1921px) {
    .overview {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1630px;
    }
    .flex_overview {
        display: grid;
        grid-auto-flow: row dense;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 1fr; 
        gap: 5px 19px; 
        grid-template-areas:
        "one two three"; 
        width: 100%; 
    }
    .flex_layout_overview {
        position: relative;
        height: min-content;
        margin: 39px 0px 0px 35px;
        min-width: auto;
        max-width: inherit;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_overview_1 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
    }
    .box_overview_2 {
        grid-area: two;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_3 {
        grid-area: three;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 685px;
    }
    .box_overview_4 {
        grid-area: one;
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
        height: 325px;
        margin-top: 360px;
    }
    .box_layout_overview {
        position: relative;
    }
    .noteButton {
        position: absolute !important;
        bottom: 10px !important;
        left: 200px;
        border: 1px solid #000 !important;
        border-radius: 25px !important;
        background: #fff !important;
        padding: 7.5px 25px !important;
    }
    .notes_title {
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .notes_title_layout {
        position: absolute;
        left: 180px;
    }
    .box_list_note_layout_overview {
        position: absolute;
        top: 45px;
        padding-left: 20px;
        padding-right: 20px;
        max-height: 604px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .box_list_note_layout_overview::-webkit-scrollbar {
        display: none;
    }
    
    
}