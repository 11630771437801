
.signOutBtn {
    background-color: #fff;
    color: #122795;
    margin-left: 50px !important;
    position: absolute;
    bottom: 25;
}
.logOutBtnIcon {
    background-color: #fff;
    color: #122795;
    position: relative;
    height: min-content;
    width: 37.5%;
    padding: 22px 29.17% 22px 19.72%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 25px "Inter", Helvetica, Arial, serif;
    text-align: center;
    letter-spacing: 0px;
    margin-top: 42px;
    margin-left: -8px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #112694;
}

.flex {
    display: flex;
    flex-direction: column;
}
.flex_layout {
    position: relative;
    height: min-content;
    margin: 0px 0px 26px;
    width: 1630px;
}
.mainFlexExpanded {
    width: 1820px
}

@media screen and (max-width: 400px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
        width: 120px;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 30px 18.1% 0px 14.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 98px;
        width: 72px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 29.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 20.5px 40px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 34px 0px 0px 16px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 18px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 18px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 34px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    .cover_block_icon_layout {
        margin-top: 22px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        position: absolute !important;
        bottom: 25px;
        margin-left: 0 !important;
        font: 16px "Inter", Helvetica, Arial, serif;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .cover_block_icon_notActive.big_title2_layout, .cover_block_icon_active.big_title2_layout {
        padding: 14px 29.17% 14px 15.72%;
    }
    .iconSignOutBtn {
        margin-top: 30px !important;
        padding: 17px 29.17% 17px 17.72% !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: fixed;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
        width: calc(100% - 1685px);
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 20px 18.1% 0px 22.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    
    .cover_block_icon_layout {
        margin-top: 42px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        margin-left: 50px !important;
        position: absolute !important;
        bottom: 25px;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: fixed;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
        width: calc(100% - 1062px);
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 20px 18.1% 0px 22.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    
    .cover_block_icon_layout {
        margin-top: 42px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        margin-left: 50px !important;
        position: absolute !important;
        bottom: 25px;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }

    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1010px;
    }
    .mainFlexExpanded {
        width: 1155px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: fixed;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
        width: calc(100% - 1222px);
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 20px 18.1% 0px 22.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    
    .cover_block_icon_layout {
        margin-top: 42px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        margin-left: 50px !important;
        position: absolute !important;
        bottom: 25px;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1165px;
    }
    .mainFlexExpanded {
        width: 1310px
    }

}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: fixed;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
        width: calc(100% - 1685px);
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 20px 18.1% 0px 22.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    .cover_block_icon_layout {
        margin-top: 42px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        margin-left: 50px !important;
        position: absolute !important;
        bottom: 25px;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1685px;
    }
    .mainFlexExpanded {
        width: 1820px
    }
}

@media screen and (min-width: 1441px) and (max-width: 1536px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: fixed;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
        width: calc(100% - 1301px);
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 20px 18.1% 0px 22.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    
    .cover_block_icon_layout {
        margin-top: 42px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        margin-left: 50px !important;
        position: absolute !important;
        bottom: 25px;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
}

@media screen and (min-width: 1921px) {
    .main {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .mainExpanded {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: 155px;
        margin-left: -145px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
        min-height: 100vh;
        height: 100vh;
    }
    .content_box {
        display: flex;
        flex-direction: column;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
        margin-left: -8px;
        min-height: 100vh;
        height: 100vh;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 20px 18.1% 0px 22.38%;
        height: 140px;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    a.cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    a.cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    a.big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title2  {
        text-decoration: none;
    }
    a.big_title2_layout {
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
    }
    a.big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    a.big_title1  {
        text-decoration: none;
    }
    a.big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    
    .SidebarToggle {
        background-color: #122795;
        border: 0;
        max-height: 161px;
    }
    
    .sideBar_layOut {
        position: relative;
        background-color: #122795;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .sideBar_layOut_mini {
        width: 60px;
        max-width: 60px;
        -webkit-transition: all 1s linear;
        -moz-transition: all 1s linear;
        -o-transition: all 1s linear;
        transition: all 1s linear;
    }
    
    .cover_block_icon_active {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive {
        display: flex;
        flex-direction: column;
        background-color: #112694;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
    
    .cover_block_icon_notActive_color {
        color: #fff !important;
    }
    
    .cover_block_icon_layout {
        margin-top: 42px;
        position: relative;
        height: min-content;
        /* margin: 42px 0px 0px 30px; */
    }
    .signOutBtn {
        background-color: #fff !important;
        color: #122795 !important;
        margin-left: 50px !important;
        position: absolute !important;
        bottom: 25px;
    }
    .logOutBtnIcon {
        background-color: #fff;
        color: #122795;
        position: relative;
        height: min-content;
        width: 37.5%;
        padding: 22px 29.17% 22px 19.72%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        text-align: center;
        letter-spacing: 0px;
        margin-top: 42px;
        margin-left: -8px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #112694;
    }
}