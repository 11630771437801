@media screen and (max-width: 400px) {
    .consultUpload_Area {
        width: 300px;
        height: 200px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 24px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
    }
    .area__icon {
        font-size: 64px;
        margin-top: 20px;
      }
}
@media screen and (min-width: 401px) and (max-width: 720px) {
    .consultUpload_Area {
        width: 300px;
        height: 200px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 24px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
    }
    .area__icon {
        font-size: 64px;
        margin-top: 20px;
      }
}
@media screen and (min-width: 721px) and (max-width: 1280px) {
    .consultUpload_Area {
        width: 300px;
        height: 200px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 24px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
    }
    .area__icon {
        font-size: 64px;
        margin-top: 20px;
      }
}
@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .consultUpload_Area {
        width: 300px;
        height: 200px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 24px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
    }
    .area__icon {
        font-size: 64px;
        margin-top: 20px;
      }
}
@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .consultUpload_Area {
        width: 300px;
        height: 30px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 18px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
        cursor: pointer;
    }
    .area__icon {
        font-size: 64px;
        margin-top: 20px;
    }
    .filesTo_Upload {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: inherit;
        height: inherit;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        background-color: #122795;
        border-radius: 12px;
        color: #fff;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 24px;
        opacity: .9;
        text-align: center;
        line-height: 1.4;
    }
}
@media screen and (min-width: 1921px) {
    .consultUpload_Area {
        width: 300px;
        height: 200px;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        font-size: 24px;
        color: #555555;
        border: 2px #c3c3c3 dashed;
        border-radius: 12px;
    }
    .area__icon {
        font-size: 64px;
        margin-top: 20px;
      }
}