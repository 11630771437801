#root {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-template-rows: 0px 1fr 0px;
    min-height: 100vh;
}

aside {
    grid-area: 2 / 1 / 4 / 2;
    margin-top: -8px;
}

@media screen and (max-width: 400px) {
    #root {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 0px 1fr 0px;
        min-height: 100vh;
    }
    
    aside {
        grid-area: 2 / 1 / 4 / 2;
        margin-top: -8px;
    }
    
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    #root {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 0px 1fr 0px;
        min-height: 100vh;
    }
    
    aside {
        grid-area: 2 / 1 / 4 / 2;
        margin-top: -8px;
    }
    
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    #root {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 0px 1fr 0px;
        min-height: 100vh;
    }
    
    aside {
        grid-area: 2 / 1 / 4 / 2;
        margin-top: -8px;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    #root {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 0px 1fr 0px;
        min-height: 100vh;
    }
    
    aside {
        grid-area: 2 / 1 / 4 / 2;
        margin-top: -8px;
    }
    
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    #root {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 0px 1fr 0px;
        min-height: 100vh;
    }
    
    aside {
        grid-area: 2 / 1 / 4 / 2;
        margin-top: -8px;
    }
    
}

@media screen and (min-width: 1921px) {
    #root {
        display: grid;
        grid-template-columns: 210px 1fr;
        grid-template-rows: 0px 1fr 0px;
        min-height: 100vh;
    }
    
    aside {
        grid-area: 2 / 1 / 4 / 2;
        margin-top: -8px;
    }
    
}