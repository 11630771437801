@media screen and (max-width: 400px) {
    .list_calendar {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_calendar {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_calendar {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_calendar_overview {
        
        content: none;
    }
    .box_layout_calendar_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
}

@media screen and (min-width: 401px) and (max-width: 720px) {
    .list_calendar {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_calendar {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_calendar {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_calendar_overview {
        
        content: none;
    }
    .box_layout_calendar_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
}

@media screen and (min-width: 721px) and (max-width: 1280px) {
    .list_calendar {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1010px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_calendar {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_calendar {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
        width: inherit;
    }
    .box_calendar_overview {
        
        content: none;
    }
    .box_layout_calendar_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 930px;
        max-width: 930px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        width: inherit;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
    .calendar_table {
        display: block;
        height: 655px;
    }
    .layout_calendar_table {
        width: inherit;
    }
    .calendar_table_head {
        width: inherit;
        display: table;
    }
    .calendar_table_body {
        width: inherit;
        display: grid;
        height: 655px;
    }
    .weekday {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .weekdayRow {
        
    }
    .weekdayRow:last-child > td {
        border-bottom: none;
    }
    .weekdayBox {
        justify-content: end;
        display: flex;
        width: 130px;
        float: left;
        height: 103px;
        border-bottom-width: 3px;
        border-bottom-color: #333;
        border-bottom-style: solid;
    }
    .weekdayBoxData {
        border-right-width: 1px;
        border-right-color: #333;
        border-right-style: solid;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }
    .weekdayBox:nth-child(7) {
        border-right: none;
    }
    .weekdayNr {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #112694;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .weekdayNrActive {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #112694;
        background-color: #fff;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .calendarDay {
        content: none;
    }
    .box_layout_calendar_nav {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
    .list_calendar {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        width: 1165px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_calendar {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_calendar {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
        width: inherit;
    }
    .box_calendar_overview {
        
        content: none;
    }
    .box_layout_calendar_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        width: inherit;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
    .calendar_table {
        display: block;
        height: 655px;
    }
    .layout_calendar_table {
        width: inherit;
    }
    .calendar_table_head {
        width: inherit;
        display: table;
    }
    .calendar_table_body {
        width: inherit;
        display: grid;
        height: 655px;
    }
    .weekday {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .weekdayRow {
        
    }
    .weekdayRow:last-child > td {
        border-bottom: none;
    }
    .weekdayBox {
        justify-content: end;
        display: flex;
        width: 215px;
        float: left;
        height: 103px;
        border-bottom-width: 3px;
        border-bottom-color: #333;
        border-bottom-style: solid;
    }
    .weekdayBoxData {
        border-right-width: 1px;
        border-right-color: #333;
        border-right-style: solid;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }
    .weekdayBox:nth-child(7) {
        border-right: none;
    }
    .weekdayNr {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #112694;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .weekdayNrActive {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #112694;
        background-color: #fff;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .calendarDay {
        content: none;
    }
    .box_layout_calendar_nav {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
    .list_calendar {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout_week {
        position: relative;
        height: 1925px;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .flex_layout_month {
        position: relative;
        height: 705px;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_calendar {
        background-color: #fff;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_calendarWeek {
        position: relative;
        height: 1925px;
        margin: 39px 37px 0px;
        border: 1px solid rgb(18, 39, 149);
    }
    .box_layout_overview_calendarMonth {
        position: relative;
        height: 850px;
        margin: 39px 37px 0px;
        border: 1px solid rgb(18, 39, 149);
    }
    .box_calendar_overview {
        content: none;
    }
    .box_layout_calendar_overview {
        position: relative;
        height: 900px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
        max-height: 60px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
    .calendar_table {
        display: block;
        height: 600px;
        background-color: #e9e9e9;
        border-radius: 25px;
    }
    .calendar_tableWeek {
        display: block;
        height: auto;
    }
    .layout_calendar_table {
        width: inherit;
    }
    .calendar_table_head {
        width: inherit;
        display: table;
        background-color: #e9e9e9;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
    .calendar_table_bodyWeek {
        width: inherit;
        display: grid;
        height: 1481px;
        /* overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none; */
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .calendar_table_bodyMonth {
        width: inherit;
        display: grid;
        /* overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none; */
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .weekday {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
        width: 212px;
    }
    .weekdayRow {
        
    }
    .weekdayRow:last-child > td {
        border-bottom: none;
    }
    .weekdayBox {
        justify-content: end;
        display: flex;
        width: 212px;
        float: left;
        height: 103px;
        border-bottom-width: 3px;
        border-bottom-color: #333;
        border-bottom-style: solid;
    }
    .weekdayBFSBox {
        justify-content: end;
        display: flex;
        width: 215px;
        float: left;
        height: 103px;
        border-bottom-width: 3px;
        border-bottom-color: #333;
        border-bottom-style: solid;
    }
    .weekdayBoxData {
        border-right-width: 1px;
        border-right-color: #333;
        border-right-style: solid;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }
    .weekdayBox:nth-child(7) > .weekdayBoxData {
        border-right: none;
    }
    .weekdayNr {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #112694;
        border: 1px solid #112694;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .weekendNr {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #888;
        border: 1px solid #112694;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .weeklyWeekdayNr {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        width: 60px;
        height: 28px;
        border-radius: 25px;
        background-color: #112694;
        border: 1px solid #112694;
        align-items: center;
        display: flex;
        justify-content: center;
        display: inline-flex;
    }
    .weeklyWeekendNr {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        width: 60px;
        height: 28px;
        border-radius: 25px;
        background-color: #888;
        border: 1px solid #112694;
        align-items: center;
        display: flex;
        justify-content: center;
        display: inline-flex;
    }
    .weekdayNrActive {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #112694;
        background-color: #fff;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .weekendNrActive {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid #112694;
        background-color: #888;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 10px;
    }
    .weeklyWeekdayNrActive {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        width: 60px;
        height: 28px;
        border-radius: 25px;
        border: 1px solid #112694;
        background-color: #fff;
        align-items: center;
        display: flex;
        justify-content: center;
        display: inline-flex;
    }
    .weeklyWeekendNrActive {
        font: 20px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        width: 60px;
        height: 28px;
        border-radius: 25px;
        border: 1px solid #112694;
        background-color: #888;
        align-items: center;
        display: flex;
        justify-content: center;
        display: inline-flex;
    }
    .calendarDay {
        content: none;
    }
    .currentDay {
        content: none;
    }
    .prevMonth {
        content: none;
    }
    .box_calendar_nav {
        font: 30px "Inter", Helvetica, Arial, serif;
        color: #112694;
    }
    .box_layout_calendar_nav {
        width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }
    .calSwitch {
        width: 270px;
        border-radius: 25px;
        border: 1px solid #112694;
    }
    .calSwitch .MuiTabs-flexContainer {
        border: 1px solid #112694;
    }
    .calSwitch button[aria-selected="true"] {
        border-radius: 25px;
        background-color: #112694;
        color: #fff;
    }
    .dayHour {
        display: flex;
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        background-color: #112694;
        color: #fff;
        width: 50px;
        height: 20px;
        justify-content: center;
        align-items: center;
        float: left;
        margin-left: -52px;
        margin-top: -10px;
    }
    .evenHour {
        background-color: #d9d9d9;
    }
    .oddHour {
        background-color: #e9e9e9;
    }
    .weeklyHour {
        width: 211.5px;
        float: left;
        height: 103px;
        border-right-width: 1px;
        border-right-color: #333;
        border-right-style: solid;
    }
    .weeklyHour:nth-child(8) {
        border-right: none;
    }
    .weeklyHour:nth-child(2) {
        border-left-width: 1px;
        border-left-color: #333;
        border-left-style: solid;
    }
    .emptyRowTdCal {
        float: left;
        width: 211.5px;
        height: 20px;
        border-right-width: 1px;
        border-right-color: #333;
        border-right-style: solid;
    }
    .emptyRowTdCal:nth-child(7) {
        border-right: none;
    }
    .emptyRowTdCal:nth-child(1) {
        border-left-width: 1px;
        border-left-color: #333;
        border-left-style: solid;
    }
}

@media screen and (min-width: 1921px) {
    .list_calendar {
        margin-left: -8px;
    }
    .block {
        display: flex;
        background-color: #fff;
    }
    .block_layout {
        position: relative;
        min-height: 1024px;
        flex-shrink: 0;
    }
    .block_item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 210px;
    }
    .content_box {
        display: flex;
        flex-direction: column;
        background-color: #122795;
    }
    .content_box_layout {
        position: relative;
        height: min-content;
    }
    .cover_block {
        display: flex;
        flex-direction: column;
    }
    .cover_block_layout {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 35px 18.1% 0px 22.38%;
    }
    .box4 {
        border: 5px solid rgba(255, 255, 255, 0.498);
        border-radius: 30px 30px 30px 30px;
    }
    .box4_layout {
        position: absolute;
        top: 0px;
        height: 125px;
        left: 0px;
        right: 0px;
    }
    .hero_title1 {
        overflow: visible;
        margin-top: 0px;
        margin-bottom: 0px;
        margin: 0px;
        font: 38.789px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
        white-space: pre-wrap;
    }
    .hero_title1_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero_title1_box_layout {
        position: relative;
        height: min-content;
        margin: 24.5px 0px;
    }
    .cover_block1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 30px 0px 0px 30px;
    }
    .cover_block1_layout {
        position: relative;
        height: min-content;
        margin: 42px 0px 0px 30px;
    }
    .big_title2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #112694;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title2_layout {
        position: relative;
        height: min-content;
        width: 51.11%;
        margin: 22px 29.17% 22px 19.72%;
    }
    .big_title1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font: 25px "Inter", Helvetica, Arial, serif;
        color: #fff;
        text-align: center;
        letter-spacing: 0px;
    }
    .big_title1_layout {
        position: relative;
        height: min-content;
        width: 64.76%;
        margin: 42px 14.52% 0px 20.71%;
    }
    .big_title1_layout1 {
        position: relative;
        height: min-content;
        width: 51.43%;
        margin: 64px 21.19% 0px 27.38%;
    }
    .big_title1_layout2 {
        position: relative;
        height: min-content;
        width: 43.81%;
        margin: 42px 25% 0px 31.19%;
    }
    .big_title1_layout3 {
        position: relative;
        height: min-content;
        width: 59.52%;
        margin: 42px 17.14% 294px 23.33%;
    }
    .block_item1 {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 0 1 1230px;
    }
    .flex {
        display: flex;
        flex-direction: column;
    }
    .flex_layout {
        position: relative;
        height: min-content;
        margin: 0px 0px 26px;
        min-width: 1693px;
    }
    .content_box1 {
        display: flex;
        flex-direction: column;
        background-color: rgba(196, 196, 196, 0.803);
        border: 1px solid rgba(174, 174, 174, 0.803);
    }
    .content_box1_layout {
        position: relative;
        height: min-content;
        max-height: 159px;
        width: 1687px;
        z-index: 999;
        margin-left: 210px;
        margin-top: -8px;
    }
    .hero_title {
        display: flex;
        align-items: center;
        justify-content: left;
        font: 500 50px "Inter", Helvetica, Arial, serif;
        color: #000;
        text-align: center;
        letter-spacing: 0px;
    }
    .hero_title_layout {
        position: relative;
        height: min-content;
        width: 45.77%;
        margin: 22.5px 52.76% 0px 1.46%;
    }
    .big_title {
        display: flex;
        align-items: center;
        font: 500 25px "Inter", Helvetica, Arial, serif;
        color: #000;
        letter-spacing: 0px;
    }
    .big_title_layout {
        position: relative;
        height: min-content;
        width: 90.57%;
        margin: 9.5px 25px 40px;
    }
    .box_calendar {
        background-color: #ebebeb;
        border-radius: 30px 30px 30px 30px;
    }
    .box_layout_overview_calendar {
        position: relative;
        height: fit-content;
        margin: 39px 37px 0px;
    }
    .box_calendar_overview {
        content: none;
    }
    .box_layout_calendar_overview {
        position: relative;
        height: 779px;
        margin: 39px 37px 0px;
        width: 1550px;
        max-width: 1550px;
    }
    .actions_box {
        border-radius: 30px 30px 30px 30px;
        background-color: #ebebeb;
        padding: 10px;
    }
    .actions_box_layout {
        display:flex;
        justify-content: flex-end;
        margin-top: 39px;
        margin-left: 39px;
        margin-right: 39px;
    }
    .booking_grid {
        display: grid; 
        grid-auto-flow: row dense; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 40px 0px; 
        grid-template-areas: 
          "one"
          "two"
          "three"
          "four"; 
        width: 100%;  
        margin-top: 50px;
    }
    
    .row_appointment_times {
        grid-area: one;
        height: 100px;
    }
    .row_appointment_details {
        grid-area: two;
        height: 50px;
    }
    .row_appointment_details2 {
        grid-area: three;
        height: 50px;
    }
    .row_appointment_buttons {
        grid-area: four;
        height: 50px;
    }
}